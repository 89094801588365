<template>
  <CreditCardMonthlyRentPayment :set-monthly-payments-to-automatic="true"/>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import CreditCardMonthlyRentPayment from "@/components/payments/CreditCardMonthlyRentPayment";

export default {
  name: "PayMonthlyRentAutomaticPage",
  components: {
    CreditCardMonthlyRentPayment,
    RFButton,
    RFLoader,
  },
  data() {
    return {

    }
  },
  computed: {

  },
  created() {

  },
  async beforeMount() {

  },
  async mounted() {

  },
  beforeDestroy() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
